.dataContainer {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: auto;
  z-index: 20;
}

@media only screen and (max-width: 1024px) {
  .dataContainer {
    width: 90%;
  }
}

@media only screen and (max-width: 512px) {
  .dataContainer {
    width: 100%;
  }
}

.waveContainer {
  display: flex;
  width: 100vw;
  position: relative;
}

.line {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgb(0, 212, 255);
  background: linear-gradient(
    180deg,
    rgba(0, 212, 255, 1) 0%,
    rgba(90, 203, 255, 1) 50%,
    rgba(102, 165, 248, 1) 100%,
    rgba(0, 0, 0, 1) 100%
  );
  overflow: hidden;
}

.line1 {
  opacity: 0.5;
  z-index: 15;
}

.line2 {
  opacity: 0.7;
  z-index: 10;
}

.line3 {
  z-index: 5;
}

.wave {
  position: absolute;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center;
}

.wave1 {
  background-image: url(https://res.cloudinary.com/dk61hw3vd/image/upload/v1629888181/1_uxq6kc.png);
  background-size: 50% 80px;
  -webkit-background-size: 50% 80px;
  animation: animate 13s linear infinite;
}

.wave2 {
  background-image: url(https://res.cloudinary.com/dk61hw3vd/image/upload/v1629888181/2_zvuzpn.png);
  background-size: 50% 100px;
  -webkit-background-size: 50% 80px;
  animation: animate 20s linear infinite;
}

.wave3 {
  background-image: url(https://res.cloudinary.com/dk61hw3vd/image/upload/v1629888181/3_wqf68r.png);
  background-size: 50% 80px;
  -webkit-background-size: 50% 80px;
  animation: animate 27s linear infinite;
}

@keyframes animate {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    transform: translateX(-25%) translateZ(0) scaleY(1);
  }
  100% {
    transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}
