.experience {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.experienceList {
  margin-top: 24px;
}

.sectionTitle {
  font-size: 24px;
  font-weight: 600;
}

.card {
  padding: 8px 25px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.logo {
  width: 150px;
}

.info {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-width: 70%;
}

.designationBrief {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.companyName {
  font-weight: 600;
}

.dates {
  color: gray;
}

.description {
  text-align: left;
}

.description p {
  margin-bottom: 0;
}

@media only screen and (max-width: 720px) {
  .card {
    flex-direction: column;
  }

  .logo {
    margin-bottom: 24px;
  }
}
