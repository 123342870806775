.header {
	display: flex;
	justify-content: space-between;
	flex: 1;
	padding: 12px;
	position: relative;
	align-items: center;
}

.menu {
	display: flex;
	flex: 1;
	justify-content: flex-end;
}

.menuIcon {
	font-size: 24px;
}

.mobileMenu {
	display: none;
}

.webMenu {
	display: flex;
}

.logo {
	display: flex;
	font-size: 36px;
	color: #5ACBFF;
	font-weight: 400;
	visibility: hidden;
}

@media only screen and (max-width: 720px) {
	.mobileMenu {
		display: flex;
	}

	.webMenu {
		display: none;
	}

	.logo {
		font-size: 24px;
	}
}