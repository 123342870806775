.mobile {
  background-color: white;
  height: 100vh;
  width: 60vw;
  position: fixed;
  right: 0;
  top: -6px;
  padding: 18px 12px;
  display: flex;
  flex-direction: column;
  z-index: 99;
}

.closeIcon {
  display: flex;
  justify-content: flex-end;
  font-size: 24px;
}

.mobileOptions {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.mobileOption {
  font-size: 24px;
  margin: 12px 0;
  display: flex;
}

.mobileOption a {
  text-decoration: none;
  color: black;
  display: flex;
}
