.about {
  padding-top: 60px;
  padding-bottom: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.picture {
  height: 420px;
}

.info {
  font-size: 24px;
}

.infoName {
  font-size: 35px;
  font-weight: 900;
}

@media only screen and (max-width: 720px) {
  .about {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .picture {
    height: 280px;
  }

  .info {
    font-size: 20px;
  }

  .infoName {
    font-size: 26px;
  }
}
