.web {
  display: flex;
  flex: 1;
  align-items: center;
}

.webOption {
  font-size: 20px;
  margin-right: 16px;
  font-weight: 400;
}

.webOption a {
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
}

.webOption:last-child {
  margin-right: 0;
}

.optionIcon {
  display: flex;
  align-items: center;
  margin-right: 4px;
}

.optionIcon i {
  display: flex;
  align-items: center;
}
