.contact {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sectionTitle {
  font-size: 24px;
  font-weight: 600;
}

.contactContainer {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
  padding: 0 25px;
}

.contactLeft {
  max-width: 60%;
}

.download {
  padding: 8px 16px;
  cursor: pointer;
  border: 2px solid #5acbff;
  border-radius: 3px;
}

.download a {
  text-decoration: none;
  color: black;
}

@media only screen and (max-width: 720px) {
  .contactContainer {
    flex-direction: column;
  }

  .download {
    margin-top: 16px;
  }
}
