.footer {
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.quote {
  font-size: 30px;
}

.author {
  color: gray;
}

.separator {
  width: 200px;
  height: 3px;
  background-color: #ccc;
}
