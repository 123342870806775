.skills {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sectionTitle {
  font-size: 24px;
  font-weight: 600;
}

.skillsContainer {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-between;
}

.skillCard {
  width: max-content;
  padding: 8px 16px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15px 5px;
}

.skillIcon {
  font-size: 80px;
}

.skillName {
  font-size: 18px;
}

@media only screen and (max-width: 720px) {
  .skillCard {
    width: max-content;
    height: 100px;
  }

  .skillIcon {
    font-size: 60px;
  }
}
